.component.description {

// 	@media (min-width: 1536px){
//
// 	    padding-top: 15rem!important;
// 		padding-bottom: 15rem!important;
// 	}

	// @include breakpoint-below("lg") {
	//    padding: 0;
	// }

	@include breakpoint("lg") {

	    // padding-left: 8rem;
	    // padding-right: 8rem;
	}

  .dot-nav {
//     margin-top: 3rem;
//
//     @include breakpoint("md") {
//       display: none;
//     }
  }

  .article-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    // @include breakpoint-between("lg", "xl") {
    //   font-size: 1.8rem;
    // }

    .item-header {
      line-height: 1.4;
    }

    .column-title {
      // @include post-item-title-size();
      margin-bottom: .6em;
    }

    // .excerpt {
    //   @include post-item-text-size();
    // }
  }

	.component-inner {
    position: relative;
    overflow: hidden;
    width: 100%;

		.slider-wrapper {

			/* hide scrollbar but allow scrolling */
      @include breakpoint-below("lg") {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
          scrollbar-width: none; /* for Firefox */
          overflow-y: scroll;


        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }

      }

			@include breakpoint-below("lg") {
				.article-item {

					&:nth-child(1) {
						margin-left: 30px;
					}

					&:nth-last-child(1) {
						padding-right: 60px;
						width: calc(60% + (60px));
					}
				}
			}

			.article-item {

				//background-color: red;
				padding: 0 1rem;

				@include breakpoint-below("lg") {
				   width: 60%;
				}


				header {
					margin-top: 2.4rem;
				}



				a {
					font-size: 1.8rem;

					@media (min-width: 1024px){
					   font-size: 2.2;
					}

					&:hover {
						text-decoration: underline;

						svg {
							margin-left: 0;
						}
					}
				}
			}
		}


	}
}
