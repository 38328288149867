main {

    .component.card.single {

        // padding-top: 7rem;
        // padding-bottom: 7rem;

        @media (min-width: 1280px){

            // padding-top: 14.4rem;
            // padding-bottom: 14.4rem;
        }

        .card-item {

            header {

                h5 {

                    margin-bottom: 1.6rem;
                }

//                 h3 {
//
//                     margin-bottom: 4rem;
//
//                     @media (min-width: 1280px){
//
//                         margin-bottom: 5.6rem;
//                     }
//
//                     @media (min-width: 1536px){
//
//                         margin-bottom: 7rem;
//                     }
//
//                 }
            }
        }

    /*

    article {

        @media (min-width: 768px){
            display: flex;
        }



      h3.medium {

        margin-bottom: 0.625rem;
        font-size: 1.375rem;

        @media (min-width: 768px){
            font-size: 1.5rem;
            line-height: 1.9rem;
            margin-bottom: 1rem;

        }

        @media (min-width: 1024px){
            font-size: 2.2rem;
            line-height: 2.55rem;
            margin-bottom: 1em;
        }

        @media (min-width: 1280px){
            font-size: 2.5rem;
            line-height: 2.75rem;
            margin-bottom: 1rem;
        }

      }

      h3.regular {
        font-size: 1.375rem;
        margin-bottom: 1.5rem;

        @media (min-width: 768px){
            font-size: 1.5rem;
            line-height: 1.9rem;
            margin-bottom: 2rem;
        }

        @media (min-width: 1024px){
            font-size: 2.2rem;
            line-height: 2.55rem;
            margin-bottom: 2.2rem;
        }

        @media (min-width: 1280px){
            font-size: 2.5rem;
            line-height: 2.75rem;
            margin-bottom: 2.5rem;
        }
      }

      a {

        font-size: 1rem;
        @media (min-width: 768px){
            font-size: 1.2rem;
        }
        @media (min-width: 1280px){
            font-size: 1.56rem;
        }

        &:hover {
            text-decoration: underline;
        }
      }
  }
  */
    }
}
