.component.studies {
	.item {

		// margin-bottom: 9.6rem;

		@media (max-width: 1023px){

			// margin-bottom: 9.6rem;
		}

		.text-column {
			h3 {

				@media (min-width: 1024px){

					font-size: 3rem;
					// line-height: 4rem;
				}

			}

      .studies-link {
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
		}
	}
}
