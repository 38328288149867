
$breakpoints: (
  'xs': 640px,
  'sm': 768px,
  'md': 992px,
  'lg': 1024px,
  'xl': 1280px,
  '2xl': 1536px,
  '3xl': 1680px,
  '4xl': 1920px,
);

@mixin breakpoint($screen) {
  @media (min-width: map-get($breakpoints, $screen)) {
    @content;
  }
}

@mixin breakpoint-below($screen) {
  @media (max-width: map-get($breakpoints, $screen) - 1) {
    @content;
  }
}

@mixin breakpoint-between($min, $max) {
  @media (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max) - 1) {
    @content;
  }
}

@mixin slide-animation {
  transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;

  &.slide-move,
  &.slide-enter,
  &.slide-enter-from,
  &.slide-leave-to {
    transition: opacity var(--menu-transition) ease 0s, transform var(--menu-transition) ease 0s;
  }

  &.slide-enter,
  &.slide-enter-from,
  &.slide-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }

  &.slide-leave-to,
  &.slide-leave-active {
    position: absolute;
  }
}

@mixin fade-animation {
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity var(--menu-transition) ease 0s;
  }

  &.fade-enter,
  &.fade-enter-from,
  &.fade-leave-to {
    opacity: 0;
    transition: opacity var(--menu-transition) ease 0s;
  }
}

@mixin grid-layout {
  display: grid;
  grid-gap: var(--row-gap) var(--column-gap);

  grid-template-columns: repeat(var(--col-count), minmax(0, 1fr));

  @include breakpoint("sm") {
    --col-count: 12;
    // grid-template-columns: repeat(var(--col-count), minmax(0, 1fr));
  }
}

@mixin body-font-size {
  font-size: 1.6rem;

  @include breakpoint("lg") {
    font-size: 2.2rem;
  }

  @include breakpoint("4xl") {
    font-size: 2.5rem;
  }
}

@mixin accordion-title-font-size {
  font-size: 2.2rem;

  @include breakpoint("lg") {
    font-size: 2.5rem;
  }

  @include breakpoint("2xl") {
    font-size: 3.2rem;
  }

  @include breakpoint("4xl") {
    font-size: 4rem;
  }
}

@mixin jumbotron-p-text {
  font-size: 1.6rem;

  @include breakpoint("xl") {
    font-size: 2.2rem;
    // line-height: 3.2rem;
    // margin-bottom: 5rem;
  }

  @include breakpoint("4xl") {
    font-size: 2.5rem;
    // line-height: 3.5rem;
    // margin-bottom: 5rem;
  }
}

@mixin block-heading-font-size {
  font-size: 2.2rem;

  @include breakpoint("lg") {
    // font-size: 2.6rem;
    font-size: 3.2rem;
  }

  @include breakpoint("2xl") {
    font-size: 3.2rem;
  }

  @include breakpoint("4xl") {
    font-size: 4rem;
  }
}

@mixin post-item-title-size {
  font-size: 2rem;

  @include breakpoint("xl") {
    font-size: 2.5rem;
  }

  @include breakpoint("2xl") {
    font-size: 2.8rem;
  }

  @include breakpoint("4xl") {
    font-size: 3.2rem;
  }
}

@mixin post-item-title-size-sm {
  font-size: 2rem;

  @include breakpoint("lg") {
    font-size: 2.2rem;
  }

  @include breakpoint("4xl") {
    font-size: 2.8rem;
  }
}

@mixin post-item-text-size {
  font-size: 1.6rem;

  @include breakpoint("lg") {
    font-size: 1.8rem;
  }

  @include breakpoint("4xl") {
    font-size: 2.2rem;
  }
}

@mixin horizontal-overflow-container-style {
  column-gap: var(--column-gap);
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  --ms-overflow-style: none;
  scrollbar-width: 0;
  padding: 0 var(--component-outer-spacing);
  max-width: none;
  display: grid;
  grid-auto-columns: minmax(var(--card-width, 40vw), 1fr);
  grid-auto-flow: column;
  grid-template-columns: unset;

  &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
  }
}

@mixin bg-color-classes() {
  @each $clr in $aTheBackgroundColors {
    .bg-#{$clr} {
      @content;
    }
  }
}

@mixin and-bg-color-classes() {
  @each $clr in $aTheBackgroundColors {
    &.bg-#{$clr} {
      @content;
    }
  }
}
