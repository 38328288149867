@import "variables";
@import "mixins";

@media screen {
  @import "overrides/components/card-multiple";
  @import "overrides/components/card-single";
  @import "overrides/components/description";
  @import "overrides/components/related";
  @import "overrides/components/text";
  @import "overrides/components/quote";
  @import "overrides/components/anchor-menu";
  @import "overrides/components/navbar";
  @import "overrides/components/hero";
  @import "overrides/components/contact";
  @import "overrides/components/studies";
  @import "overrides/components/resources";
  @import "overrides/components/accordion";
  @import "overrides/components/preamble";
  @import "overrides/components/login";
  @import "overrides/components/feature";
  @import "overrides/components/enhanced";
  @import "overrides/components/search";
  @import "overrides/components/guide";
  @import "overrides/components/linkblock";
  @import "overrides/components/linkbuttonsblock";
  @import "overrides/components/category";
  @import "overrides/layouts/posts";
  @import "overrides/layouts/archive";
  @import "overrides/layouts/contact";
  @import "overrides/layouts/ressurs";
}


.component:last-of-type {
  // &:is(.enhanced) {
  //   @apply mb-10;
  // }
}
