.component.anchor {
  --color-foreground: var(--color-light-grey_1);
  padding: 2rem var(--component-outer-spacing) 0;

  @include breakpoint("lg") {
    padding: 4rem var(--component-outer-spacing);
  }

  .icon {
    transition: opacity var(--button-transition) ease 0s, transform var(--button-transition) ease 0s;
  }

  .toggle-menu {
    color: var(--color-foreground);
    padding-bottom: 2rem;

    .icon-x {
      margin-left: auto;
      opacity: 0;
      transform: translateY(-1rem);
    }

    &:hover,
    &:active,
    &:focus {
      --color-foreground: var(--color-teal_1);
    }

    @include breakpoint("lg") {
      display: none;
    }
  }

  .menu-item {
    cursor: pointer;
    color: var(--color-foreground);
    display: flex;
    align-items: center;
    gap: 2rem;

    .icon {
      flex: none;
      display: none;
      margin-top: .4rem;

      @include breakpoint("lg") {
        display: flex;
      }
    }

    &:hover {
      text-decoration: underline;
      --color-foreground: var(--color-teal_1);

      .icon {
        transform: translateY(.5rem);
      }
    }
  }

  .menu-container {
    --max-height: none;
    transition: max-height var(--button-transition) ease 0s;

    > .inner {
      padding: 2rem 0 4rem;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      transition: transform var(--button-transition) ease 0s, opacity var(--button-transition) ease 0s;

      @include breakpoint("lg") {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5rem;
        padding: 0;
      }
    }
  }

  @include breakpoint-below("lg") {
    .menu-container {
      max-height: var(--max-height);
      overflow: hidden;
    }

    &.-closed {
      .menu-container {
        max-height: 0;

        > .inner {
          opacity: 0;
          transform: translateY(-3rem);
        }
      }

      .toggle-menu {
        &:hover,
        &:active,
        &:focus {
          .icon.arrow {
            transform: translateY(.5rem);
          }
        }
      }
    }

    &.-open {
      &:not(.-closing) {
        .toggle-menu {
          .icon-x {
            opacity: 1;
            transform: translateY(0rem);
          }
        }

        .menu-container {
          border-top: .1rem solid var(--color-foreground);

          > .inner {
            opacity: 1;
            transform: translateY(0rem);
          }
        }
      }

      &.-closing {
        .toggle-menu {
          &:hover,
          &:active,
          &:focus {
            .icon.arrow {
              transform: translateY(.5rem);
            }
          }
        }

        .menu-container {
          max-height: 0px;
        }
      }
    }
  }
}
