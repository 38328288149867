main {
.component.guide {

	// padding-top: 11.5rem;
	overflow: hidden;


	.component-inner {

    .guide-item {
      position: relative;

      .path {
        // transform: scale(1.4);
        position: absolute;
        display: flex;
        @include breakpoint-below("sm") {
          height: 100%;
        }

        @include breakpoint("sm") {
          width: 80%;
          bottom: 0;
        }

        &.-path_0,
        &.-path_2 {
          right: 0;
          transform-origin: center right;
        }

        &.-path_0 {
          aspect-ratio: 142.9 / 392.4;

          @include breakpoint("sm") {
            aspect-ratio: 503 / 372.8;
            transform: scale(.7) translate(-20%, 50%);
          }

          @include breakpoint("md") {
            transform: scale(1) translate(0%, 30%);
          }

          @include breakpoint("xl") {
            transform: scale(1) translate(30%, 30%);
          }
        }

        &.-path_1,
        &.-path_3 {
          aspect-ratio: 134 / 304.3;
          transform-origin: center left;

          @include breakpoint("sm") {
            aspect-ratio: 461.6 / 353.4;
            transform: scale(.7) translate(-140%, 100%);
          }

          @include breakpoint("md") {
            transform: scale(1) translate(-100%, 80%);
          }

          @include breakpoint("xl") {
            transform: scale(1) translate(-70%, 92%);
          }
        }

        &.-path_2 {
          aspect-ratio: 142.9 / 392.4;

          @include breakpoint("sm") {
            aspect-ratio: 580.2 / 320.7;
            // transform: scale(.7) translate(100%, 150%);
            transform: scale(.7) translate(90%, 90%);
          }

          @include breakpoint("md") {
            // transform: scale(1) translate(80%, 100%);
            transform: scale(.8) translate(100%, 70%);
          }

          @include breakpoint("xl") {
            // transform: scale(1) translate(80%, 50%);
            transform: scale(1) translate(60%, 70%);
          }
        }

        .graphic {
          flex: auto;
          overflow: visible;

          &.-mobile {
            display: flex;

            @include breakpoint("sm") {
              display: none;
            }
          }

          &.-desktop {
            display: none;

            @include breakpoint("sm") {
              display: flex;
            }
          }
        }
      }

      .text-column {
        position: relative;
      }

      .image-column {
        position: relative;

        .path {
//           position: absolute;
//           display: flex;
//           @include breakpoint-below("sm") {
//             height: 100%;
//           }
//
//           @include breakpoint("sm") {
//             width: 50%;
//           }
        }
      }
    }
	}

	header {

		// margin-bottom: 6.9rem;


		@include breakpoint("md") {
			margin-bottom: 0;
		}

		h2 {

			font-size: 2.6rem;

			@media (min-width: 768px){
			    font-size: 3.5rem;
			}

			@media (min-width: 1024px){
			    font-size: 5rem;
			}
		}
	}

	.guide-item {

    @include grid-layout();
    --row-gap: 2rem;

		position: relative;

    @include breakpoint("sm") {
      align-items: center;

      .text-column, .image-column {
        grid-column: span 6;
      }

      .image-column {
        aspect-ratio: 1;
      }
    }

    &:nth-child(odd) {
      .text-column {
        @include breakpoint("sm") {
          grid-column: 2 / span 5;
        }

        @include breakpoint("lg") {
          grid-column: 2 / span 5;
        }

        @include breakpoint("xl") {
          grid-column: 2 / span 4;
        }

        @include breakpoint("2xl") {
          grid-column: 2 / span 3;
        }
      }

      .image-column {
        @include breakpoint("sm") {
          grid-column: 7 / span 6;
        }

        @include breakpoint("lg") {
          // grid-column: 6 / span 5;
          grid-column: 7 / span 6;
        }

        @include breakpoint("xl") {
          grid-column: 7 / span 5;
        }
      }
    }

    &:nth-child(even) {
      .image-column {
        @include breakpoint("sm") {
          order: -1;
          grid-column: 1 / span 6;
        }

        @include breakpoint("lg") {
          grid-column: 1 / span 6;
          // grid-column: 2 / span 5;
        }

        @include breakpoint("xl") {
          grid-column: 2 / span 5;
          // grid-column: 3 / span 4;
        }
      }

      .text-column {
        @include breakpoint("sm") {
          grid-column: span 5;
        }

        @include breakpoint("lg") {
          grid-column: 7 / span 5;
        }

        @include breakpoint("xl") {
          grid-column: 8 / span 4;
        }

        @include breakpoint("2xl") {
          grid-column: 9 / span 4;
        }

        @include breakpoint("2xl") {
          grid-column: 9 / span 3;
        }
      }
    }

		.block-header {
			margin-bottom: 0;

			div {
				max-width: 100%;
			}

			.title {
        @apply font-SuisseIntlBold;
				font-size: 1.6rem;
				margin-bottom: 1.28rem;

				@media (min-width: 768px){
				    font-size: 2.4rem;
				}

				@media (min-width: 1024px){
				    font-size: 2.5rem;
				}


			}

			.lead {
				@include jumbotron-p-text;
				padding-bottom: 4.8rem;
			}
		}
	}
}
}


