.component.feature {

	// padding-top: 2rem!important;


		@media (min-width: 1024px){
		    // padding-top: 8rem!important;
		    // padding-bottom: 12.8rem!important;
		}



	header {

// 		h3.medium {
//
// 			@include breakpoint("lg") {
// 			    font-size: 2.6;
// 			    // line-height: 3.2rem;
// 			    margin-bottom: 2.4rem;
// 			}
//
// 			@include breakpoint("xl") {
// 			    font-size: 3.2rem;
// 			    // line-height: 4rem;
// 			    margin-bottom: 3.8rem;
// 			}
//
//       @include breakpoint("2xl") {
//         font-size: 4rem;
//       }
// 		}

		.lead {

			margin-bottom: 3.2rem;
			font-size: 1.6rem;

			@include breakpoint(xl) {
			    margin-bottom: 6.4rem;
			    font-size: 1.9rem;
			}
		}

		a {
			font-size: 1.6rem;

      @include breakpoint(lg) {
			// @media (min-width: 1024px){
			    font-size: 1.9rem;
			}

      @include breakpoint(xl) {
			// @media (min-width: 1280px){
			    font-size: 2.5rem;
			}
		}
	}
}
