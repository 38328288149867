.hero {
	// min-height: 80vh!important;
	// overflow: hidden;

	&:not(.superimposed) {


		.hero-wrapper {
			// min-height: 80vh;
		}
	}

	.hero-wrapper {
		// min-height: 80vh;
	}

	.hero-wrapper-image {

		img {
			//display:block;
			//position:absolute;
			//left:0;
			//right:0;
			//bottom:0;
			//margin:auto;

			position: relative;

			    // top: 50%;
			    // transform: translateY(-50%);
		}
	}

	// .hero-header .title + * {
	//     // margin-top: 2.4rem;
	//     margin-top: 3.8rem;
	// }
  .hero-header {

  }
}

.hero.superimposed {

	.hero-wrapper-image {

			&:after {

				content: "";
				position: absolute;
				width: 100%;
				height:100%;
				top:0;
				left:0;
				background: rgb(255,255,255);
				background: linear-gradient(226deg, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0.3) 50%);
		}


		//background: rgb(255,255,255);
		//background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 55%);
	}
}

.hero.page, .hero.post, .hero.ressurs, .hero.illustration {
	.column-left {

		.hero-header {
			h1 {
				margin-top: 1.6rem;
// 				font-size: 3rem;
//
// 				@include breakpoint("lg") {
// 			    font-size: 4rem;
// 				}
//
//         @include breakpoint("2xl") {
//           font-size: 5rem;
//         }
//
// 				@include breakpoint("4xl") {
// 			    font-size: 6rem;
// 				}
//
			}

			h3,
      .lead {
        font-size: 2.2rem;

        @include breakpoint("lg") {
          font-size: 2.6rem;
        }

        @include breakpoint("2xl") {
          font-size: 3.2rem;
        }

        @include breakpoint("4xl") {
          font-size: 4rem;
        }

			}
		}
	}
}

.hero.illustration {
  .column-left {
    @include breakpoint(lg) {
      justify-content: center;
    }
  }
}

.hero.page.content {

	.hero-wrapper {

		@media (max-width: 1023px){
		    align-items: start;

		}

		.column-left {

			justify-content: center;
			align-self: stretch;
			position: relative;

			header {

				margin-bottom: 3.2rem;

				@media (min-width: 1024px){
				    margin-bottom: 8.3rem;
				}

				.label {

					font-size: 1.6rem;

					@media (min-width: 1024px){
					    font-size: 2.2rem;
					}
				}
			}
		}

		.column-right {

			@media (min-width: 1536px){
				// margin-left: -16rem;
			}

			.break-words {
//
// 				padding-top: 5rem;
// 				padding-bottom: 5rem;
//
// 				@media (min-width: 1536px){
// 					padding: 9.6rem;
// 					// padding-right: 16rem;
// 					// margin-right: -16rem;
// 				}
//
// 				>h5 {
//
// 					margin-bottom: 1.6rem;
//
// 				}
//
// 				.prose {
//
// 					margin-bottom: 4rem;
//
// 					@media (min-width: 1536px){
// 						margin-bottom: 8.4rem;
// 					}
// 				}

			}

			@media (min-width: 1536px){

			}


			ul {

				li {

					// margin-bottom: .8rem;
				}

				a {
// 					font-size: 1.8rem;
//
// 					@media (min-width: 1280px){
// 						font-size: 2.4rem;
// 					}

					svg {
						display: none;
					}


				}

			}

		}

	}
}

.hero.page.icon {

	.column-right {

		figure {

			svg {
				//max-height: 5.625rem;

				@media (min-width: 768px){
					//max-height: 8.75rem;
				}
			}
		}
	}
}


.hero.post, .hero.ressurs {

	.hero-header {

		h3.regular {

			margin-top: 1.6rem;

			@media (min-width: 768px){

				// line-height: 3.2rem;
			}

			@media (min-width: 1024px){

				margin-top: 1.6rem;
				// font-size: 3.2rem;
				// line-height: 4.8rem;
			}

			@media (min-width: 1536px){

				margin-top: 3.2rem;
				// font-size: 3.2rem;
				// line-height: 4.8rem;
			}




		}
	}

}


.hero.post.content, .hero.ressurs {

	.hero-header {

		h3.regular {

			margin-top: 1.6rem;

			@media (min-width: 768px){

				// line-height: 3.2rem;
			}

			@media (min-width: 1024px){

				margin-top: 1.6rem;
				// font-size: 3.2rem;
				// line-height: 4.8rem;
			}

			@media (min-width: 1536px){

				margin-top: 3.2rem;
			}




		}
	}

	aside {

		margin-top: 2rem;


		@media (min-width: 768px){
			margin-top: 4.8rem;
		}

		@media (min-width: 1024px){
			margin-top: 6.2rem;
		}

	}

	.column-right {

		.hero-assets {

			padding-top: 5rem;
			padding-bottom: 5rem;

			>h5 {

				margin-bottom: 1.6rem;

			}

			.prose {

				//margin-bottom: 2.5rem;

				@media (min-width: 1536px){
					//margin-bottom: 5.25rem;
				}

				p {

					font-size: 1.6rem;

					@media (min-width: 1024px){
						font-size: 2.5rem;
						// line-height: 3.5rem;
					}
				}
			}

      .asset {
        margin-bottom: 0;

        a,
        button {
          align-items: baseline;
          color: var(--color-black);

          .icon {
            // margin-right: 1.6rem;
            transform: translateY(.1em);
          }
        }

      }

		}

		ul {

			li {

// 				margin-bottom: .8rem;
//
// 				@media (min-width: 1024px){
// 					margin-bottom: 1.6rem;
// 				}

// 				a, button {
// 					svg {
// 						margin-right: .8rem;
// 					}
//
// 					span {
//
// // 						font-size: 1rem;
// // 						line-height: 1.5rem;
// //
// // 						@media (min-width: 1024px){
// // 							font-size: 1.25rem;
// // 							line-height: 1.75rem;
// // 						}
// //
// // 						@media (min-width: 1280px){
// // 							font-size: 1.5rem;
// // 							line-height: 2rem;
// // 						}
//
//
// 					}
//
// 				}
			}
		}

	}

}

