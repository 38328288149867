.component.linkButtonsBlock {
  .block-link-item {
    --color-background: var(--color-teal_1);
    --color-foreground: var(--color-petrol_1);
    display: flex;

    .block-link {
      flex: auto;
      align-items: center;
      justify-content: center;
      column-gap: var(--column-gap);
      text-align: left;
      color: var(--color-foreground);
      background-color: var(--color-background);
      padding: 3rem;
      display: flex;

      @include breakpoint("4xl") {
        padding: 4rem;
      }

      .title {
        flex: auto;
      }

      &:hover,
      &:active,
      &:focus {
        --color-background: var(--color-petrol_1);
        --color-foreground: var(--color-teal_2);
      }
    }
  }
}
