.component.accordion {

  .toggle {
    --color-foreground: var(--color-petrol_1);
    color: var(--color-foreground);
  }

	@media (min-width: 1536px){

		// padding-top: 11.5rem!important;
		// padding-bottom: 11.5rem!important;
	}

	.accordion-item-details {

		.accordion-item-details-inner {

			background-color: #fff;

		}
	}

// 	.accordion-item:not(.is-active) {
// 		.accordion-item-title {
//
// 			svg {
// 				transition: all 100ms ease-out;
// 			}
//
// 			&:hover {
//
// 				svg {
// 					margin-top: 1.6rem;
// 				}
// 			}
// 		}
// 	}
}
