.component.search {

	header {
		h1 {

			font-size: 3rem;

			@media (min-width: 1280px){
				font-size: 4.4rem;
			}

			@media (min-width: 1536px){
				font-size: 6rem;
			}
		}
	}

	.hero-aside {

		.bookmark-wrapper {

			button {

				span {

					@media (max-width: 767px){
						// font-size: 1.6rem;

					}
				}
			}
		}
	}

}
