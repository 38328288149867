.type.post.ressurs {
  // .section.paragraph, .section.media, .section.oembed, .section.quote {
  //   margin-top: var(--vertical-block-spacing-lg);
  //   margin-bottom: var(--vertical-block-spacing-lg);
  // }
}

.component.resources {
  .resources-list {
    .card {
      display: grid;
      align-content: flex-start;
      row-gap: 3rem;
      padding-bottom: 3rem;
      background-color: var(--color-light-grey_1);

      .card-content,
      .card-assets,
      .card-content {
        padding: 0 3rem;
      }

      .card-header {
        padding: 1.6rem 3rem;
        min-height: 72px;
        display: flex;
        gap: 1.6rem;
        justify-content: space-between;
        background-color: var(--color-pink_1);

        @media (min-width: 1024px){
          min-height: 84px;
        }
      }

      .card-content {
        .heading-link {
          &:hover,
          &:active,
          &:focus {
            text-decoration: underline;
          }
        }
        .program-label {
          color: #585858;
          display: inline-block;
        }
      }
    }

    .card-assets {
      display: grid;
      row-gap: 1rem;

      .icon.asset {
        transform: translateY(.2em);
      }

      .asset-link {
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}

.toggle-bookmark, .bookmark-article {

  &:hover {

    path {
      d: path("M384 48V512l-192-112L0 512V48C0 21.5 21.5 0 48 0h288C362.5 0 384 21.5 384 48z");
      d: "M384 48V512l-192-112L0 512V48C0 21.5 21.5 0 48 0h288C362.5 0 384 21.5 384 48z";
    }
  }
}

.resources__list_loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0.7);
  z-index: 1000;

  &-image {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    transform: translate(-50%, -50%);
    background-image: url("/assets/images/loader.svg");
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

