//do not refer directly to this array in styling. Use the generated css variables instead
$theme-colors: (
  'black': #020202,
  'white': #ffffff,
  'petrol_1': #093640,
  'blue_1': #355EDB,
  'blue_2': #DAE2FF,
  'blue_3': #ECF0FF,
  'red_1': #E31836,
  'pink_1': #FCE7EA,
  'teal_1': #7CE9CC,
  'teal_2': #DEFAF2,
  'yellow_1': #FDB200,
  'light-grey_1': #F7F5F3,
  'light-grey_2': #FBFAF9,
  'dark-grey_1': #585858,
);

// we are going to use these elsewhere in the theme, to target any and all background-color-classes.
$aTheBackgroundColors: "purple-dark", "purple-light", "gray-dark", "gray-light", "green", "green-light", "red";

@mixin generate-theme-colors($key, $value) {
  $scale-value: adjust-color($value, $lightness: calc( 0 / 2 * 1%));
  --color-#{$key}: #{$scale-value};
  --color-#{$key}-rgb: #{red($scale-value)},#{green($scale-value)},#{blue($scale-value)};

  --color-foreground: var(--color-black);
}

:root {
  --menu-transition: .3s;
  --button-transition: .2s;
  --component-outer-spacing: 3.8rem;
  --column-gap: 3rem;
  --row-gap: 0;

  // --block-vertical-spacing: 11.5rem;

  --vertical-block-spacing-sm: 2rem;
  --vertical-block-spacing-md: 3rem;
  // --vertical-block-spacing-lg: 7rem;
  --vertical-block-spacing-lg: 5rem;
  // --vertical-block-spacing-xl: 11.5rem;
  --vertical-block-spacing-xl: 9rem;

  --default-transition: .5s;

  --content-max-width: 164rem;

  --col-count: 1;

  @each $key, $value in $theme-colors {
    @include generate-theme-colors($key, $value); // this generates css variables for use in the scss
  }
}
