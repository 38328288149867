.component.related + .component.enhanced {
  margin-top: 0;
}

.component.related.extended {
  .article-link {

    .post-image-container {
      width: 100%;
    }

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
	.grid {

		article {
			overflow: hidden;
		}

	}
}


@media (min-width: 1536px) {
	.component.related {
	    // padding-bottom: 11.2rem!important;
	    // padding-top: 11.2rem!important;
	}
}

.component.related.simple {

	@media (min-width: 1536px){

		// padding-top: 11.5!important;
		// padding-bottom: 11.5rem!important;
	}

	@media (max-width: 767px){
	    padding-top: 0;

	}


	article {

		header {

			padding: 0;
			padding-right: 3.2rem;
		}
	}
}

.component.related.simple,
.section.related.simple {
  .article-link {
    .article-title {
      text-decoration: underline;
    }

    a {
      &:hover,
      &:active,
      &:focus {
        .article-title {
          text-decoration: none;
        }
      }
    }
  }
}



.component.related.extended,
.section.related.extended {
	.article-link {
		.link-header {
			padding: 2.5rem 3rem;

      @include breakpoint("sm") {
        padding: 2.5rem;
      }

      @include breakpoint("xl") {
        padding: 3rem;
      }

			@include breakpoint("4xl") {
		    padding: 4rem;
			}
		}

    .article-title {
      margin-bottom: .2em;
      // @include breakpoint("xl") {
      //   margin-bottom: .2em;
      // }
    }

    a {
      .link-btn {
        gap: 2rem;
      }

      .icon {
        --color-foreground: var(--color-teal_1);
        transition: transform var(--button-transition) ease 0s;
      }

      &:hover,
      &:active,
      &:focus {
        .article-title {
          text-decoration: underline;
        }

        .arrow {
          transform: translateX(.5rem);
        }
      }
    }
	}
}

.component.related {

	.grid {
		article {
			a {
				&:hover {

					h3 {
						text-decoration-line: none;
					}
				}
			}
		}
	}

}



.component.category, .section.related, .section.category {

	@include breakpoint-below("sm") {
	    padding-right: 0;
	    padding-left: 0;

	}

	@media (max-width: 767px){
	     figure {

	     	// height: 300px;
	     }

	}

	.component-inner > header {
		@include breakpoint-below("sm") {
	    padding: 0 3.2rem;
	    margin-right: 3.2rem;
		}
	}


	.items-header {

		margin-bottom: 2.4rem;
		font-size: 2.2rem;

		@media (min-width: 1024px){
		   margin-bottom: 5rem;
		}

	}

	article {

// 		&:first-of-type {
// 			@include breakpoint-below("sm") {
// 		    margin-left: 3.2rem;
// 			}
// 		}
//
// 		&:nth-of-type(2n) {
// 			@include breakpoint-below("sm") {
// 		    margin: 0 1.6rem;
// 			}
// 		}
//
// 		&:last-of-type {
// 			@include breakpoint-below("sm") {
// 		    margin-right: 3.2rem;
// 			}
// 		}

		button {
			svg {
				transition: all 100ms ease-out;
			}
		}

		&:hover {

			button {

				span {
					text-decoration: underline;
				}

				svg {
					margin-left: .46rem;
				}
			}
		}

		header {

			.medium {

				margin-bottom: 1.6rem;



				@media (min-width: 1024px){

					font-size: 3rem;
				}

				@media (min-width: 1024px){
				   margin-bottom: 1.6rem;
				}
			}

			.lead {
				font-size: 1.6rem;

				@media (min-width: 1024px){
				   font-size: 2.5rem;
				}
			}

			button {

				span {

					font-size: 1.6rem;

					@media (min-width: 1024px){
					   font-size: 1.9rem;
					}

					@media (min-width: 1536px){
					   font-size: 2.5rem;
					}
				}
			}
		}

		figure {
			/*
			margin-bottom: 1.875rem;

			@media (min-width: 1024px){

			   margin-bottom: 2.5rem;
			}
			*/



		}
	}

	.posts {

	}

}
