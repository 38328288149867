.component.twocolumn {



	@include breakpoint(md) {

		margin-top: 4rem;
		margin-bottom: 4rem;
		padding: 0;

	}

	@media (min-width: 1280px) {

		margin-top: 7.1875rem;
		margin-bottom: 5.1875rem;
		padding: 0;

	}


	.grid {

		.flex-row {

			gap: .5rem;

			div {

				@include breakpoint(md) {

					font-size: 1.25rem;
					line-height: 1.7rem;

				}

				@media (min-width: 1280px) {

					font-size: 1.5rem;
					line-height: 2rem;

				}
			}

		}

		.text-green-dark, .text-brand-green {

			padding-left: 1.5rem;
			padding-right: 1.5rem;

			@include breakpoint(md) {

				padding-left: 3rem;
				padding-right: 3rem;
			}


			header {

				h4 {

					@include breakpoint(md) {

						font-size: 1.5rem;
						line-height: 2rem;
					}
					@media (min-width: 1280px) {

						font-size: 1.875rem;
						line-height: 2.25rem;

					}
				}
			}

			.content {

				p {
					@include breakpoint(md) {

						font-size: 1.25rem;
						line-height: 1.7rem;

					}

					@media (min-width: 1280px) {

						font-size: 1.5rem;
						line-height: 2rem;

					}


				}

				.text-xl {

					@media (max-width: 1023px) {

						align-self: flex-start;
						margin-top: 4rem;
					}

					@include breakpoint(md) {

						font-size: 1.25rem;
						line-height: 1.7rem;
						margin-top: 10rem;
						padding-bottom: 0;
					}

					@media (min-width: 1280px) {

						font-size: 1.5rem;
						line-height: 2rem;

					}
				}

				a {

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.text-green-dark, .text-brand-green {

			@include breakpoint(md) {

				padding-bottom: 2rem;
			}
		}
	}
}


.component.team {
	.component-inner {
//     .team-links {
//       display: grid;
//       gap: 3rem;
//
//       @include breakpoint(sm) {
//         gap: 4rem;
//       }
//
//       @include breakpoint(md) {
//         gap: 7rem;
//       }
//     }

// 		article {
// 			.content {
// 				@include breakpoint(md) {
//
// 					padding-left: 1rem;
// 				}
//
// 				h5 {
// 					margin-bottom: 1.5rem;
// 				}
//
//
//
// 				.contact {
//
// 					.flex-row {
//
// 						@include breakpoint(md) {
//
// 							margin-bottom: 1rem;
// 						}
//
//
// 						.font-bold, .font-regular {
//
// 							@include breakpoint(md) {
//
// 								font-size: 1.375rem;
// 							}
// 						}
// 					}
//
//
// 				}
// 			}
//
// 			header {
//
// 				h3 {
//
// 					a {
//
// 						@include breakpoint(md) {
//
// 							font-size: 1.5625rem;
// 						}
// 					}
// 				}
// 			}
// 		}
	}
}
