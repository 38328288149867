.component.categoryInclude {

	article {

		margin-bottom: 4rem;


		@media (min-width: 768px) {
			
			flex-direction: row;
			align-items: flex-end;
		}

		@include breakpoint(md) {
			
			margin-bottom: 4rem;
		}

		

		header {
			h3 {

				margin-bottom: 0.625rem;

				@include breakpoint(md) {
					
					margin-bottom: 1.2rem;
				}



				a {
					text-decoration: underline;
					font-size: 1.25rem;
					line-height: 1.75rem;

					@include breakpoint(md) {
						
						font-size: 1.5625rem;
						line-height: 2.2rem;
					}

					@media (min-width: 1536px) {
						
						font-size: 2rem;
						line-height: 2.75rem;
					}
				}
			}

			.lead {
				font-size: 1rem;
				line-height: 1.625rem;

				@include breakpoint(md) {
					
					font-size: 1.2rem;
					line-height: 2rem;
				}


				@media (min-width: 1536px) {
					
					font-size: 1.375rem;
					line-height: 2.5rem;
				}
			}
		}
	}

}