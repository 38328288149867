.component.login {
  .login-form {
    .links {
      display: flex;
      gap: var(--column-gap);
      align-items: baseline;
      margin-top: 4rem;
    }
  }

	.hero-aside {

// 		button.green {
// 			padding: 1.9rem;
//
// 			@media (min-width: 1280px){
// 				padding-right: 4rem;
// 				padding-left: 4rem;
// 				padding-top: 3.2rem;
// 				padding-bottom: 3.2rem;
// 			}
//
// 			color: #7CE9CC;
//
// 			&:hover {
// 				color: #093640;
// 			}
//
// 		}

		.forgot-password {
			text-decoration: underline;
			// margin-left: 4rem;
			// font-size: 1.6rem;

			// @include breakpoint(sm) {
			// 	font-size: 2.2rem;
			// }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
		}
	}
}
