.component.enhanced {

	// margin-left: var(--component-outer-spacing);
	// margin-right: var(--component-outer-spacing);

	// padding-top: 7rem;
	// padding-bottom: 7rem;

	@media (min-width: 1024px){
		// padding-top: 11.5rem;
		// padding-bottom: 11.5rem;
	}

	@media (min-width: 1536px){

	    // padding-top: 3.5rem;
	    // padding-bottom: 0;

	    // margin-left: auto!important;
	    // margin-right: auto!important;
	}

	.component-inner {

// 		h3.medium {
// 			font-size: 3.2rem;
// 			// margin-bottom: 3.5rem;
//
// 			@include breakpoint("sm") {
// 			    font-size: 2.9rem;
// 			    // margin-bottom: 3.8rem;
// 			    // line-height: 4rem;
// 			}
//
// 			@include breakpoint("xl") {
// 				font-size: 3.2rem;
// 				// margin-bottom: 4.8rem;
// 				// line-height: 4.8rem;
//
// 			}
//
// 			@include breakpoint("2xl") {
// 			    font-size: 4rem;
//
// 			    // margin-bottom: 7rem;
// 			    // line-height: 5.6rem;
// 			}
// 		}

    .subtitle {
      line-height: inherit;
      font-size: inherit;
    }

// 		.lead {
//       line-height: inherit;
// 			font-size: 1.6rem;
//
// 			@media (min-width: 768px){
// 			    font-size: 1.9rem;
// 			}
//
// 			@media (min-width: 1280px){
// 			    font-size: 2.5rem;
// 			    // line-height: 3.5rem;
// 			}
// 		}

		.links {

			.component-inner {
				margin-bottom: 0;
			}

      a {
        --color-foreground: var(--color-blue_1);
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
// 			a.button-text {
// 				font-size: 1rem;
//
// 				&:hover {
//
// 					text-decoration: none;
//
// 					span {
// 						text-decoration-line: none;
// 					}
//
// 				}
//
// 				@media (min-width: 768px){
// 				    font-size: 1.9rem;
// 				}
//
// 				@media (min-width: 1280px){
// 				    font-size: 2.5rem;
// 				    line-height: 3.5rem;
// 				}
// 			}
		}

		.readmore {

			margin-top: 3.5rem;

			@media (min-width: 1024px){
			    margin-top: 4.8rem;
			}
		}

		.items-end {

			justify-content: flex-end;

			@media (min-width: 1024px){
				margin-right: 3.2rem;
			}
		}
	}
}
