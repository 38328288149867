.component.category {

	header {

		a {

			@media (min-width: 1024px){
			    font-size: 2.2rem;
			}

			
		}
	}
}