.component.resources {

	.filter {

		h5 {

			font-family: "Suisse Intl Regular", Helvetica, Arial, sans-serif;

		}
	}
}
