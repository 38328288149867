.component.linkBlock {

	.component-inner {

		.flex-wrap {

			@media (max-width: 1023px){
			    justify-content: end;
			}
			
		}
	}

	figure {

		@media (max-width: 1023px){
		    margin-top: 50px;

		}
	}

	.grid {

		a {

			svg {

				display: none;
			}
		}
	}
}