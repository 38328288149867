.component.preamble {

	p {

		font-size: 2.2rem;
		// line-height: 3.2rem;

		@media (min-width: 1024px){
			font-size: 3.2rem;
			// line-height: 4.8rem;

		}

		@media (min-width: 1280px){
			font-size: 3.2rem;
			// line-height: 4.8rem;

		}
	}

}
