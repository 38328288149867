.component.quote {

	h2 {
		font-size: 2.6rem;

		@media (min-width: 1024px){
		    
		    font-size: 4rem;
		}

		@media (min-width: 1536px){
		    
		    font-size: 5;
		}
	}

}