@mixin jumbotron-p-text {

	font-size: 1.6rem;

	@media (min-width: 1024px){
	    font-size: 2.24rem;
	    line-height: 3.2rem;
	    margin-bottom: 5rem;
	}

 	@media (min-width: 1280px){
 	    font-size: 2.5rem;
 	    line-height: 3.5rem;
 	    margin-bottom: 5rem;
 	}
 }

.component.jumbotron {
	.internalButton,
	.externalButton {

		margin-top: 0;

		@media (min-width: 1024px){
		    //margin-top: 2rem;
		}

		a.green, a.green-light {
		    flex: 1 1 auto;
		    // font-size: 1.9rem;
		    // line-height: 2.8rem;
		    //width: max-content;
		    max-width: 100%;
		    // padding: 2rem;

		    // transition: background-color 300ms ease-out;

		    span {
		    	// transition: padding-right 200ms ease-out 100ms;
		    }

		    svg {

		    	min-height: 1.9rem;
		    	@media (min-width: 1280px){
		    	    min-height: 2.8rem;
		    	}
		    	//transition: all 200ms;
		    	//transition: padding-right 200 linear 200;
		    	//transition: padding-right 200ms ease-out 200ms;
		    }

		    &:hover {

		    	// padding-right: 2.7rem;

		    	span {
		    		// padding-right: .48rem;

		    	}

		    	svg {

		    	}

		    }


		    @media (min-width: 1280px){
		        //width: 50%;
		        max-width: 100%;
		        // padding: 3.2rem;
		        // font-size: 3.2rem;
		        // line-height: 3.8rem;
		    }
		}

// 		.text-brand-green.green {
// 			color: #7CE9CC;
// 			&:hover {
// 				color: #093640;
// 				background-color: #7CE9CC;
// 			}
// 		}
//
// 		.text-brand-green.green-light {
// 			background-color: #7CE9CC;
//
// 			&:hover {
// 				color: #7CE9CC;
// 				background-color: #093640;
// 			}
// 		}

		.text-brand-pink.pink {
			color: #FCE7EA;
			&:hover {
				color: #fff;
				background-color: red;
			}
		}

	}
}
//
// section .button-text {
//
// 	svg {
// 		transition: all 100ms ease-out;
// 	}
//
// 	&:hover {
//
// 		text-decoration: underline;
//
//     .icon {
//       transform: translateX(.6rem);
//     }
//
// 		svg {
//
// 			// margin-left: .48rem;
//
// 		}
// 	}
// }
//
// .button-text {
//
// 	&:hover {
// 		text-decoration: underline;
// 	}
// }

// .component:last-of-type {
//   &:is(.enhanced) {
//     margin-bottom: 16rem;
//   }
// }
