.type-post {

	.hero-inner {

		h1 {

			margin-bottom: 1rem;

			@media (min-width: 1536px){
			    margin-bottom: 2.625rem;
			}
		}

		.lead {

			line-height: 2rem;
			
			@media (min-width: 768px){
			    
			}

			@media (min-width: 1024px){
			    line-height: 2.4rem;
			}

			@media (min-width: 1280px){
			    line-height: 3.375rem;
			}
		}
	}
}
