


.component.card.multiple {

    padding-bottom: 8rem!important;
    padding-top: 8rem!important;

    @media (min-width: 1536px) {
        padding-bottom: 8rem!important;
        padding-top: 8rem!important;
    }

    .card-item {
      display: grid;
      --row-gap: 5rem;

      @include grid-layout();

      .card-header,
      .illustration {
        @media (min-width: 768px) {
          grid-column: span 6;
        }
      }

      .card-header {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &:nth-child(odd) {
        @media (min-width: 1280px) {
          .card-header {
            grid-column: 2 / span 5;
          }

          .illustration {
            grid-column: span 5;
          }
        }
      }

      &:nth-child(even) {
        @media (min-width: 768px) {
          .illustration {
            order: -1;
          }
        }

        @media (min-width: 1280px) {
          .illustration {
            grid-column: 2 / span 5;

          }

          .card-header {
            grid-column: 8 / span 4;
          }
        }
      }


      .title {

        margin-bottom: 0.625rem;
        // font-size: 2.2rem;

        @media (min-width: 768px){
            // font-size: 2.4rem;
            // line-height: 30rem;
            margin-bottom: 1.6rem;

        }

        @media (min-width: 1024px){
            // font-size: 3.5rem;
            // line-height: 4.1rem;
            margin-bottom: 1.6em;
        }

        @media (min-width: 1280px){
            // font-size: 4rem;
            // line-height: 4.4rem;
            margin-bottom: 1.6rem;
        }

      }

      .subtitle {
        font-size: 2.2;
        margin-bottom: 1.4rem;

        @media (min-width: 768px){
            // font-size: 2.4rem;
            // line-height: 3rem;
            margin-bottom: 3.2rem;
        }

        @media (min-width: 1024px){
            // font-size: 3.5rem;
            // line-height: 4.1rem;
            margin-bottom: 3.5rem;
        }

        @media (min-width: 1280px){
            // font-size: 4rem;
            // line-height: 4.4rem;
            margin-bottom: 4rem;
        }
      }

      .read-more-link {
        margin-top: 2rem;
//         font-size: 1.6rem;
//
//         @media (min-width: 768px){
//             font-size: 1.9rem;
//         }
//         @media (min-width: 1280px){
//             font-size: 2.5rem;
//         }
//
//         &:hover {
//             text-decoration: underline;
//         }
      }
  }
}
